var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('router-view', {
    key: _vm.$i18n.locale
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }